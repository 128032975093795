<template>
  <div>
    <vx-card class="mb-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <div class="w-full">
            <vs-input
              id="permission_name"
              class="w-full required"
              v-validate="'required|max:191'"
              :label="$t('permission.name')"
              v-model="model.name"/>
            <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
          </div>
          <div class="w-full">
            <vs-input
              id="permission_display_name"
              class="w-full required"
              v-validate="'required|max:191'"
              :label="$t('permission.display_name')"
              v-model="model.display_name"/>
            <span class="text-danger text-sm" v-show="errors.has('display_name')">{{errors.first('display_name')}}</span>
          </div>
          <div class="w-full">
            <vs-input
              id="permission_guard_name"
              class="w-full required"
              v-validate="'required|max:191'"
              :label="$t('permission.guard_name')"
              v-model="model.guard_name"/>
            <span class="text-danger text-sm" v-show="errors.has('guard_name')">{{errors.first('guard_name')}}</span>
          </div>
          <div class="w-full">
            <vs-textarea
              id="functionality_description"
              :label="$t('functionality.description')"
              v-model="model.description" />
            <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
          </div>
        </div>
        <div class="w-full flex gap-2 justify-end">
          <vs-button
            v-if="!isEdit()"
            :disabled="!validateForm"
            v-permission="'permissions.create'"
            @click="createOrUpdate">
            {{ $t('action.save') }}
          </vs-button>
          <vs-button
            v-if="isEdit()"
            :disabled="!validateForm"
            v-permission="'permissions.edit'"
            @click="createOrUpdate">
            {{ $t('action.save') }}
          </vs-button>

          <vs-button
            type="border"
            @click="cancel">
            {{ $t('common.back') }}
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import PermissionService from '@/services/api/PermissionService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      name : '',
      guard_name: '',
      display_name: null,
      description: ''
    },
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.name)
            && !this.isEmpty(this.model.display_name)
            && !this.isEmpty(this.model.guard_name)
            && !this.isEmpty(this.model.description)
    }
  },
  beforeMount() {
    this.service = PermissionService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model.name = response.name
          this.model.display_name = response.display_name
          this.model.guard_name = response.guard_name
          this.model.description = response.description
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
          id: this.id,
          name: this.model.name,
          display_name : this.model.display_name,
          guard_name : this.model.guard_name,
          description: this.model.description,
        }

      this.service.createOrUpdate(modelData).then(
          data => {
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/permissions/${data.id}/edit`)
          },
          error => {
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        ).finally(
          () => {this.$vs.loading.close()}
        )
    },
    cancel() {
      this.$router.push('/permissions')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit(){
      return !_.isNil(this.id)
    }
  }
}
</script>

<style>

</style>
